<template>
  <div class="wrap container app page" role="document">
    <div class="content">
      <main class="main">
        <section><h1>The App</h1><h5>&quot;Simple. Excellent. Very high quality and intentional design.
          Facilitates prayer in a way that works within my 2020 brain and helps bridge the gap to rich tradition
          and the wealth of faith.&quot;</h5>
          <div class="hero-image">
            <div class="animate"
                 data-anim-path="https://hallow.com/wp-content/themes/hallow/dist/images/animation_the_app_b0a1a647413ca52d70ae.json"></div>
          </div>
        </section>
        <p class=""></p>
        <div class="feature-block" data-block_5f5eadd953446>
          <div class="block-device device dark-bezel">
            <div class="screen">
              <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_app_wherever.jpg"
                     data-play-on-visible
                     muted playsinline loop>
                <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_app_wherever.mp4"
                        type="video/mp4">
              </video>
            </div>
          </div>
          <div class="block-content"><h2>Wherever, Whenever</h2>
            <p>Listen on the way to work, on a plane, in the morning, or at night with downloadable offline
              sessions and customized lengths anywhere from 1, 5, 10, 15, 30, or 60 minute options!</p></div>
        </div>
        <div class="feature-block has-media-on-the-right" data-block_5f5eae0053447>
          <div class="block-device device dark-bezel">
            <div class="screen">
              <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_app_pray_your_way.jpg"
                     data-play-on-visible
                     muted playsinline loop>
                <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_app_pray_your_way.mp4"
                        type="video/mp4">
              </video>
            </div>
          </div>
          <div class="block-content"><h2>Pray Your Own Way</h2>
            <p>Personalize your prayer experience. Choose your guide, length, background music like Gregorian
              chant, set your favorites, and create your own personal prayer plan.</p></div>
        </div>
        <div class="feature-block" data-block_5f5eae1653448>
          <div class="block-device device dark-bezel">
            <div class="screen">
              <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_app_family.jpg"
                     data-play-on-visible
                     muted playsinline loop>
                <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_app_family.mp4"
                        type="video/mp4">
              </video>
            </div>
          </div>
          <div class="block-content"><h2>Prayer Families</h2>
            <p>Connect with your friends, family, prayer group, parish, &amp; community to share prayers,
              intentions, or journal reflections and grow together in your faith.</p></div>
        </div>
        <blockquote class="wp-block-quote"><p>The AIWEIYI app is an attempt to share these prayer techniques &#8211;
          the same ones that changed my life &#8211; with as many people as we can. We believe that prayer and
          meditation has the power to change the world. We believe that if we let Him, God will AIWEIYI &#8211;
          meaning &#8220;make holy&#8221; &#8211; our lives.</p>
        </blockquote>
        <div class="feature-block has-media-on-the-right" data-block_5f5eae9d53449>
          <div class="block-device device dark-bezel">
            <div class="screen">
              <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_app_habits.jpg"
                     data-play-on-visible
                     muted playsinline loop>
                <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_app_habits.mp4"
                        type="video/mp4">
              </video>
            </div>
          </div>
          <div class="block-content"><h2>Build a Habit</h2>
            <p>Prayer takes practice and discipline; it requires showing up and spending time with God. AIWEIYI
              helps you build a habit of prayer. You can set prayer reminders, invite others to pray with you,
              and track your prayer goals and streaks.</p></div>
        </div>
        <div class="feature-block" data-block_5f5eaebc5344a>
          <div class="block-device device dark-bezel">
            <div class="screen">
              <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_app_journal.jpg"
                     data-play-on-visible
                     muted playsinline loop>
                <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_app_journal.mp4"
                        type="video/mp4">
              </video>
            </div>
          </div>
          <div class="block-content"><h2>Journal</h2>
            <p>Reflect on your prayer, document your spiritual journey, and commit to growing in virtue with the
              prayer journal.</p></div>
        </div>
        <section class="get-started-block" data-block_5f5eaee65344c>
          <div class="get-started-trial">
            <div class="get-started-trial-content"><h2>Get started now.</h2>
              <p>Start your free 14 day trial today and try the 9-day intro to contemplative prayer
                challenge.</p> <a class="button button-black" href="/Preview" target="_blank">Start Your Free 14 day
                trial</a></div>
            <div class="get-started-trial-illustration"><img
                class='lazyload'
                src='https://hallow.com/wp-content/themes/hallow/dist/images/get_started_now_77b82d9f7736be3d9d9e.png'/>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
export default {
name: "Features"
}
</script>

<style scoped>

</style>
