<template>
  <footer class="footer">
    <div class="container" style="padding-top: 20px">
      <div class="footer-main">
        <div class="brand">AIWEIYI</div>
        <nav class="nav-primary">
          <div class="menu-footer-menu-container"></div>
        </nav>
        <div class="social-links">
          <!--          <a class="facebook" href="https://facebook.com/"></a>-->
          <!--          <a class="instagram" href="https://instagram.com/"></a>-->
        </div>
        <div class="store-links">
          <a class="app-store" href="/Preview" target="_blank"></a>
          <a class="play-store" href="/Preview" target="_blank"></a>
        </div>
      </div>
      <div class="footer-trailer">
        <div class="footer-copyright">
          &copy; {{ new Date().getFullYear() }} AIWEIYI TECHNOLOGY PTE. LTD.
        </div>
        <!--        <div class="footer-props"><a href="#cookie-manager">Manage Cookies</a> <a href="/privacy-policy">Privacy-->
        <!--          Policy</a> <a href="/terms-of-service">Terms of Service</a> <a href="/copyright">Copyright</a></div>-->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "HallowFooter",
};
</script>

<style scoped>
</style>
