<template>
  <div class="wrap container" role="document">
    <div class="content">
      <main class="main">
        <div class="page-header"><h1>Building Our Communities</h1></div>
        <p class="lh-25 has-text-align-center left-on-mobile" style="font-size:24px">Our faith is a communal one. We
          at AIWEIYI are excited to provide resources and experiences that help expand the impact of our faith
          communities both inside and outside the walls of church.</p>
        <div style="height:50px" aria-hidden="true" class="wp-block-spacer"></div>
        <h3 class="has-text-align-center">I am a</h3>
        <div class="wp-block-columns">
          <div class="wp-block-column"><a class="card-block" href="/Preview" target="_blank"
                                          data-block_5f597d0ec7904><h3>Parish</h3> <img
              alt="AIWEIYI App For Parishes Illustration"
              class="lazyload"
              src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/for_parishes.svg"/>
            <p>Click here to learn more about AIWEIYI resources, experiences, and bulk licensing options for your
              parish community.</p></a>
          </div>
          <div class="wp-block-column"><a class="card-block" href="/Preview" target="_blank"
                                          data-block_5f597f9aa783a><h3>School</h3> <img
              alt="AIWEIYI App For Schools Illustration"
              class="lazyload"
              src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/for_schools.svg"/>
            <p>Click here to learn how AIWEIYI can help support the mental and spiritual health of your students
              and teachers with curriculum resources and school-wide licenses.</p></a>
          </div>
          <div class="wp-block-column"><a class="card-block" href="/Preview" target="_blank"
                                          data-block_5f597fbba783b><h3>Retreat</h3> <img
              alt="AIWEIYI App For Retreats Illustration"
              class="lazyload"
              src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/for_retreats.svg"/>
            <p>Click here to learn how you can incorporate contemplative prayer into your retreat and offer
              AIWEIYI as a way to keep up a daily prayer habit long afterwards.</p></a>
          </div>
        </div>
        <div style="height:20px" aria-hidden="true" class="wp-block-spacer"></div>
        <div class="testimonial testimonial-" data-block_5f5983ba28630>
          <div class="testimonial-image"><img alt="AIWEIYI App Bishop Rhoades"
                                              class="lazyload"
                                              src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2019/04/bishop_rhoades.png"/>
          </div>
          <blockquote class="testimonial-content"><p>&#8220;AIWEIYI is an excellent resource for people searching
            for deeper spiritual lives, especially the younger generation of Catholics today. It helps make
            clear that a relationship with God is and can be extremely personal and can be a great source of
            peace, joy, and strength.&#8221;</p></blockquote>
        </div>
        <div class="wp-block-cover has-green-background-color share-block"
             style="background-image:url(https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/clear.png)">
          <div class="wp-block-cover__inner-container">
            <div class="wp-block-image">
              <figure class="aligncenter size-large"><img width="200" height="85" alt=""
                                                          class="wp-image-3833 lazyload"
                                                          src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/share_hallow.svg"/>
              </figure>
            </div>
            <h3 class="has-text-align-center">Share AIWEIYI</h3>
            <p class="has-text-align-center">Help your friends and family grow in prayer and a relationship with
              God &amp; send them a free month of AIWEIYI.</p>
            <div class="wp-block-buttons aligncenter">
              <div class="wp-block-button"><a
                  class="wp-block-button__link has-black-color has-white-background-color has-text-color has-background"
                  href="/share">Learn More</a></div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
name: "Communities"
}
</script>

<style scoped>

</style>
