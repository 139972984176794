<template>
  <main class="main-style">
    <div class="sticky-trigger"></div>
    <!---->
    <section
      data-v-a3fd9bac=""
      class="block block--grid"
      style="
        --content-width: 1600px;
        --grid-width: 1224px;
        --column-count: 12;
        --content-padding: 16px;
        --rows: 26;
        --width: 1224px;
        --m-rows: 8;
        --row-gap: 16px;
        --row-size: 48px;
        --column-gap: 24px;
        --block-padding-top: 16px;
        --block-padding: 16px 0px 16px 0px;
        --block-padding-right: 0px;
        --block-padding-bottom: 16px;
        --block-padding-left: 0px;
        --m-block-padding: 40px 16px 64px 16px;
        --oldContentWidth: 1600px;
        --header-height: nullpx;
      "
    >
      <div
        data-v-a3fd9bac=""
        class="block-background"
        style="--background-color: #dad3d0; --background-overlay-opacity: 0"
      >
        <!---->
        <!---->
      </div>
      <div
        data-v-1f24cc0f=""
        data-v-a3fd9bac=""
        class="block-grid"
        id="block0"
        isfirst="true"
      >
        <div
          data-v-189c24f4=""
          data-v-1f24cc0f=""
          data-qa="gridtextbox:tnplswopk"
          class="block-grid-item use-m-margin grid__item"
          style="
            --text: center;
            --align: flex-start;
            --justify: center;
            --position: 1/8/3/10;
            --m-element-margin: 0 0 64px 0;
            --element-z-index: 5;
          "
        >
          <div
            data-v-189c24f4=""
            class="text-box block-grid-item__component"
            id="tNPLSWopK"
            lcp="[object Object]"
            mobile-block-padding="40px 16px 64px 16px"
            element-width="184"
            element-height="112"
          >
            <!-- <p class="body-large"><span style="color: #000000">Lino</span></p> -->
          </div>
        </div>
        <div
          data-v-189c24f4=""
          data-v-1f24cc0f=""
          data-qa="gridtextbox:-kxgvvlqf"
          class="block-grid-item use-m-margin grid__item"
          style="
            --text: left;
            --align: flex-start;
            --justify: center;
            --position: 7/10/9/15;
            --m-element-margin: 0 0 32px 0;
            --element-z-index: 2;
          "
        >
          <div
            data-v-189c24f4=""
            class="text-box block-grid-item__component"
            id="-kxGvvlqf"
            lcp="[object Object]"
            mobile-block-padding="40px 16px 64px 16px"
            element-width="496"
            element-height="112"
          >
            <h1>
              <span class="coming" style="color: #000000">Coming soon</span>
            </h1>
          </div>
        </div>
        <div
          data-v-189c24f4=""
          data-v-1f24cc0f=""
          data-qa="gridtextbox:jptjbsimu"
          class="block-grid-item use-m-margin grid__item"
          style="
            --text: left;
            --align: flex-start;
            --justify: center;
            --position: 9/10/11/15;
            --m-element-margin: 0 0 32px 0;
            --element-z-index: 1;
          "
        >
          <div
            data-v-189c24f4=""
            class="text-box block-grid-item__component"
            id="JptjbsImu"
            lcp="[object Object]"
            mobile-block-padding="40px 16px 64px 16px"
            element-width="496"
            element-height="112"
          >
            <p class="body-large">
              <span style="color: #000000"
                >We are working on our website. Stay tuned for some exciting
                updates.</span
              >
            </p>
          </div>
        </div>
        <div
          data-v-189c24f4=""
          data-v-1f24cc0f=""
          data-qa="gridimage:6yctz0iix"
          class="block-grid-item use-m-margin grid__item"
          style="
            --align: center;
            --justify: center;
            --position: 3/2/18/9;
            --element-z-index: 4;
          "
        >
          <div
            data-v-00c3b48d=""
            data-v-189c24f4=""
            title=""
            class="block-grid-item__component image"
          >
            <img
              data-v-00c3b48d=""
              data-qa="builder-gridelement-image"
              alt=""
              src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=764,h=944,fit=crop/lino053c7/HeroImg-Yr0xqvKpQLiQk7zO.jpg"
              sizes="(min-width: 920px) 764px, calc(100vw - 32px)"
              height="944"
              width="764"
              loading="eager"
              class="image__image image__image--reset-m-position"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "InnerPage",
};
</script>

<style scoped lang="scss">
.main-style {
  .block {
    position: relative;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    width: 100%;
    min-width: 100%;
  }
  .block-grid {
    position: relative;
    z-index: 14;
    display: grid;
    grid-area: 1/1/-1/-1;
    grid-template-rows: repeat(26, 48px);
    grid-template-columns:
      calc(100vw / 2 - 1600px / 2) 1fr repeat(
        12,
        minmax(0, var(--column-width))
      )
      1fr calc(var(--container-width) / 2 - var(--content-width) / 2);
    grid-auto-rows: var(--row-size);
    row-gap: var(--row-gap);
    column-gap: var(--column-gap);
    width: 100%;
    padding: var(--block-padding);
    margin: 0 auto;
    --column-gap-count: calc(var(--column-count) - 1);
    --column-width: calc(
      (var(--grid-width) - var(--column-gap-count) * var(--column-gap)) /
        var(--column-count)
    );
    --container-width: 100vw;
  }
  @media screen and (max-width: 920px) {
    .block-grid {
      display: flex;
      flex-direction: column;
      grid-gap: 0;
      padding: var(--m-block-padding);
    }

    .block-grid.has-zero-m-vertical-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    .block-grid--mobile-full-height {
      justify-content: center;
      min-height: calc(100vh - var(--header-height, 0px));
    }
  }

  .block-grid {
    grid-template-rows: repeat(var(--rows), minmax(var(--row-size), auto));
  }
  .block-grid-item {
    position: relative;
    z-index: var(--element-z-index);
    display: flex;
    flex-direction: column;
    grid-area: var(--position);
    align-items: var(--align);
    justify-content: var(--justify);
    padding: var(--grid-item-padding);
    overflow: hidden;
    text-align: var(--text);
  }

  @media screen and (max-width: 920px) {
    .block-grid-item {
      grid-area: auto;
      grid-column: 3/-3;
      align-items: var(--m-align, var(--align));
      align-self: var(--m-align-self);
      justify-content: var(--m-justify, var(--justify));
      max-width: 100%;
      text-align: var(--m-text, var(--text));
    }
  }

  .block-grid-item {
    pointer-events: none;
  }

  .block-grid-item__component {
    pointer-events: all;
  }

  @media screen and (max-width: 920px) {
    .block-grid-item {
      width: var(--m-width, 100%);
    }

    .block-grid-item.use-m-margin {
      margin: var(--m-element-margin);
    }

    .block-grid-item.use-m-margin:last-of-type {
      margin-bottom: 0;
    }
  }
  .block-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 13;
    overflow: hidden;
    background-color: #dad3d0;
    transition: background-color 0.3s ease-in-out, height 1ms linear 999s;
  }
  .text-box {
    width: 100%;
    padding: 0;
    margin: 0;
    overflow-wrap: break-word;
    outline: none;
  }
  .image {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  .image__image--unstyled,
  .image__image {
    width: 100%;
    height: 100%;
  }
  .image__image {
    position: absolute;
    display: block;
    object-fit: var(--object-fit, cover);
    border-radius: var(--border-radius);
  }
  @media screen and (max-width: 920px) {
    .image__image--reset-m-position[data-v-00c3b48d] {
      position: static;
      height: auto;
    }
  }

  .text-box h1 {
    margin-bottom: 48px;
  }

  .text-box h1:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 920px) {
    .text-box h1 {
      margin-bottom: 48px;
    }
  }
  .text-box h1 {
    font-size: 80px;
    margin: 0;
    padding: 0;
    line-height: 1.2;
    text-align: left;
  }
  @media screen and (max-width: 920px) {
    .text-box h1 {
      font-size: 36px;
    }
  }
}
</style>
