<template>
  <div class="wrap container" role="document">
    <div class="content">
      <main class="main">
        <div class="page-header"><h1>How To Pray</h1></div>
        <p class="has-text-align-center"><strong>&#8220;Contemplative prayer, in my opinion, is nothing else than an
          intimate sharing between friends; it means taking time frequently to be alone with Him who we know loves
          us.&#8221;<br>St. Teresa of Avila</strong></p>
        <div style="height:50px" aria-hidden="true" class="wp-block-spacer"></div>
        <div class="wp-block-columns">
          <div class="wp-block-column">
            <figure class="wp-block-image size-large featured-blog-image"><a
                href="/howToPray"><img width="1024" height="611"
                                                                       alt="AIWEIYI App - How to Pray"
                                                                       class="wp-image-7980 lazyload"
                                                                       src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2021/06/31_l-1024x611.png"/>
            </a></figure>
          </div>
          <div class="wp-block-column"><h4 class="featured-prayer-title"><a
              href="/howToPray">How to Pray: 6 Tips on Getting Started in
            Prayer</a></h4>
            <p class="">New to prayer or a seasoned prayer warrior? Learn new tips from the AIWEIYI team on how
              you can deepen your prayer life, build a routine, and draw closer to God.</p></div>
        </div>
        <div style="height:50px" aria-hidden="true" class="wp-block-spacer"></div>
        <div class="blog-posts " data-block_5f524b7f7ee0c>
          <article
              class="post-1377 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App Examen Chapter Illustration"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/examen.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: Daily Examen</a>
            </h5>
              <p>Notice God&#039;s presence throughout your day.</p></div>
          </article>
          <article
              class="post-2126 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App AIWEIYI Lectio Divina Illustration"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/lectio-divina.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: Lectio
              Divina</a></h5>
              <p>Encounter the Bible more profoundly.</p></div>
          </article>
          <article
              class="post-1345 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail">
              <img alt="AIWEIYI App Illustration of Christian Meditation"
                   class="attachment-medium size-medium wp-post-image lazyload"
                   src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/christian-meditation.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray:
              Christian Meditation</a></h5>
              <p>Find God&#039;s peace in silence.</p></div>
          </article>
          <article
              class="post-4025 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life tag-how-to-pray tag-lords-prayer tag-our-father">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App How to Pray Lord&#039;s Prayer Our Father"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/our-father.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: Lord&#8217;s
              Prayer (Our Father)</a></h5>
              <p>A line-by-line breakdown of the prayer Jesus gave us</p></div>
          </article>
          <article
              class="post-5134 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life category-uncategorized">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App Blog How to Pray With Music"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/12/tbd-5-500x299.png"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: With Music</a>
            </h5>
              <p>Encounter God in music throughout your day.</p></div>
          </article>
          <article
              class="post-6278 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life tag-prayer tag-saints">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App Daily Saint Prayer"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2021/04/14_l.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: With Saints</a>
            </h5>
              <p>Journey with the Saints who lead us to lives of service, prayer, and love.</p></div>
          </article>
          <article
              class="post-1426 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App Rosary Chapter Illustration"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/rosary.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray The Rosary</a></h5>
              <p>Meditate on the life of Jesus with vocal prayer.</p></div>
          </article>
          <article
              class="post-5606 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life tag-7-sorrows-of-mary tag-hallow-lent tag-hallow-pray-40 tag-lent">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                                                                                            alt="AIWEIYI App Blog - How To Pray - the 7 Sorrows Rosary"
                                                                                            class="attachment-medium size-medium wp-post-image lazyload"
                                                                                            src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2021/02/14_l-500x299.png"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: 7 Sorrows Rosary</a></h5>
              <p>Pray alongside Mary through the 7 sorrows of Christ&#039;s life.</p></div>
          </article>
          <article
              class="post-4002 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail">
              <img alt="AIWEIYI App How to Pray Stations of the Cross"
                   class="attachment-medium size-medium wp-post-image lazyload"
                   src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/14_l.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray:
              Stations of the Cross</a></h5>
              <p>Reflect on this challenging prayer following Jesus on the day he died.</p></div>
          </article>
          <article
              class="post-3999 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail">
              <img alt="AIWEIYI App Illustration of Divine Mercy Chaplet"
                   class="attachment-medium size-medium wp-post-image lazyload"
                   src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/divine-mercy-chaplet.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: Divine
              Mercy Chaplet</a></h5>
              <p>Pray for mercy with the prayer Jesus gave St. Faustina in 1935.</p></div>
          </article>
          <article
              class="post-1424 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App How to Pray Night Prayer"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2019/09/37_l.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: The Liturgy of
              the Hours- Night Prayer</a></h5>
              <p>End your day with the final prayer of the Liturgy of the Hours.</p></div>
          </article>
          <article
              class="post-1185 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App How to Pray Falling Asleep"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2019/08/40_l.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: Falling
              Asleep</a></h5>
              <p>Learn tips to help you fall asleep in prayer.</p></div>
          </article>
          <article
              class="post-4011 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life tag-glorious-mysteries tag-how-to-pray tag-joyful-mysteries tag-novena tag-sorrowful-mysteries">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App How to Pray 54 Day Novena"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/73_l.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: 54 Day
              Novena</a></h5>
              <p>Pray 27 Rosaries in petition and 27 Rosaries in thanksgiving.</p></div>
          </article>
          <article
              class="post-4028 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="How to Pray with Kids | AIWEIYI"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/05/13030219/How-To-Pray-1500x900-1-500x300.png"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray with Kids: A Guide
              to Catholic Children’s Prayers</a></h5>
              <p>Learn how to pray together as a family.</p></div>
          </article>
          <article
              class="post-4065 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life tag-adoration tag-contemplation tag-contrition tag-holy-hour tag-petition tag-prayer tag-thanksgiving">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App Blog - How to Pray - Holy Hour"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/100_l-500x299.png"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: Holy Hour</a></h5>
              <p>Dedicate a full hour in prayer to God.</p></div>
          </article>
          <article
              class="post-4016 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App How to pray novena"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/73_l-1.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: A Novena</a></h5>
              <p>Learn this traditional prayer method taking place over 9 days.</p></div>
          </article>
          <article
              class="post-5874 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life tag-aramaic tag-hallow-app tag-lords-prayer tag-lords-prayer-aramaic tag-our-father">
            <a href="/Preview" target="_blank" class="post-thumbnail">
              <img alt="Jonathan Roumie on AIWEIYI App - Our Father in Aramaic"
                   class="attachment-medium size-medium wp-post-image lazyload"
                   src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2021/02/79_l-500x299.png"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: Lord&#8217;s
              Prayer in Aramaic</a></h5>
              <p>Pray the Lord&#039;s Prayer in the language Jesus taught it.</p></div>
          </article>
          <article
              class="post-4022 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                                                                                                   alt="AIWEIYI App Vocal Prayer Chapter Illustration"
                                                                                                   class="attachment-medium size-medium wp-post-image lazyload"
                                                                                                   src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/vocal-prayer.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: ACTS</a></h5>
              <p>Remind yourself the steps of prayer to guide your conversation with God.</p></div>
          </article>
          <article
              class="post-4019 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App How to Pray Pirate Prayer"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/27_l.svg"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: Pirate
              Prayer</a></h5>
              <p>Learn the &quot;ARRR&quot; acronym to guide you in prayer for any amount of time.</p></div>
          </article>
          <article
              class="post-7606 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life tag-gospels">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App 30 Day Gospel Challenge Jonathan Roumie"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="@/assets/img/95_l-copy.png"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: The Gospels</a>
            </h5>
              <p>Draw closer to Jesus as you meditate on the Gospels.</p></div>
          </article>
          <article
              class="post-8591 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized">
            <a href="/Preview" target="_blank"
               class="post-thumbnail"> <img
                                            alt="AIWEIYI App - How to Pray: Mary, Undoer of Knots Novena"
                                            class="attachment-medium size-medium wp-post-image lazyload"
                                            src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2021/07/Screen-Shot-2021-07-28-at-10.55.01-AM-500x298.png"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray:
              Mary, Undoer of Knots Novena</a></h5>
              <p>Pray this novena to help untangle the &quot;knots&quot; in your life.</p></div>
          </article>
          <article
              class="post-8672 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life category-uncategorized">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="How to Pray - St Therese Novena - AIWEIYI App"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2021/09/19204605/Screen-Shot-2021-09-14-at-5.21.05-PM-500x297.png"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: St. Thérèse
              Novena</a></h5>
              <p>Pray this novena to grow your trust in God&#039;s love for you.</p></div>
          </article>
          <article
              class="post-8588 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life category-uncategorized tag-how-to-pray tag-novena tag-saint-prayer tag-st-jude">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI App - How to Pray - St. Jude Novena"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2021/10/12202750/Screen-Shot-2021-10-12-at-12.12.29-PM-500x295.png"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: St. Jude
              Novena</a></h5>
              <p>Pray this novena to lead you through times of struggle, pain, and challenge.</p></div>
          </article>
          <article
              class="post-9774 post type-post status-publish format-standard has-post-thumbnail hentry category-editorial category-uncategorized">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                width="500" height="299" alt="AIWEIYI App How to Pray Latin Rosary"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2021/10/01001715/Latin-Rosary-L-500x299.png"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray: Latin Rosary</a>
            </h5>
              <p>Pray the Rosary in its original language: Latin.</p></div>
          </article>
          <article
              class="post-10754 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life tag-hail-mary tag-how-to-pray tag-rosary">
            <a href="/Preview" target="_blank" class="post-thumbnail"> <img
                alt="AIWEIYI app How to pray Hail Mary"
                class="attachment-medium size-medium wp-post-image lazyload"
                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2022/01/06033319/93_l-500x299.png"/>
            </a>
            <div class="post-summary"><h5 class="entry-title"><a
                href="/Preview" target="_blank">How to Pray The Hail
              Mary</a></h5>
              <p>Let Mary’s mighty fiat inspire your life with this prayer.</p></div>
          </article>
        </div>
        <div class="testimonial testimonial-" data-block_5f524d64e44cd>
          <div class="testimonial-image"><img alt="AIWEIYI App Bishop Rhoades"
                                              class="lazyload"
                                              src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2019/04/bishop_rhoades.png"/>
          </div>
          <blockquote class="testimonial-content"><p>&#8220;AIWEIYI is an excellent resource for people searching
            for deeper spiritual lives, especially the younger generation of Catholics today. It helps make
            clear that a relationship with God is and can be extremely personal and can be a great source of
            peace, joy, and strength.&#8221;</p></blockquote>
        </div>
        <div class="wp-block-cover has-green-background-color share-block"
             style="background-image:url(https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/clear.png)">
          <div class="wp-block-cover__inner-container">
            <div class="wp-block-image">
              <figure class="aligncenter size-large"><img width="200" height="85" alt=""
                                                          class="wp-image-3833 lazyload"
                                                          src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/share_hallow.svg"/>
                <noscript><img width="200" height="85"
                               src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/share_hallow.svg"
                               alt="" class="wp-image-3833"/></noscript>
              </figure>
            </div>
            <h3 class="has-text-align-center">Share AIWEIYI</h3>
            <p class="has-text-align-center">Help your friends and family grow in prayer and a relationship with
              God &amp; send them a free month of AIWEIYI.</p>
            <div class="wp-block-buttons aligncenter">
              <div class="wp-block-button"><a
                  class="wp-block-button__link has-black-color has-white-background-color has-text-color has-background"
                  href="/share">Learn More</a></div>
            </div>
          </div>
        </div>
        <div class="wp-block-columns help has-white-color has-dark-blue-background-color has-text-color has-background">
          <div class="wp-block-column help-content"><h3 class="">Have a question?</h3>
            <p class="">We are here to help you with any questions or concerns you may have in regards to
              AIWEIYI! Don&#8217;t hesitate to reach out.</p>
            <div class="wp-block-buttons">
              <div class="wp-block-button is-style-fill"><a
                  class="wp-block-button__link has-black-color has-white-background-color has-text-color has-background"
                  href="/Preview" target="_blank">Contact Us!</a></div>
            </div>
          </div>
          <div class="wp-block-column">
            <div
                class="wp-block-group help-image has-black-color has-light-blue-background-color has-text-color has-background">
              <div class="wp-block-group__inner-container">
                <figure class="wp-block-image size-large is-style-default"><img width="370" height="518"
                                                                                alt="AIWEIYI is here to help"
                                                                                class="wp-image-3730 lazyload"
                                                                                src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/help.svg"/>
                  <noscript><img width="370" height="518"
                                 src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/help.svg"
                                 alt="AIWEIYI is here to help" class="wp-image-3730"/></noscript>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <section class="related-posts-block" data-block_5f59711358e97><h2>Resources</h2>
          <div class="carousel-wrapper">
            <div class="carousel" id="carousel">
              <div class="carousel-block">
                <article
                    class="post-24469 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
                  <a class="post-thumbnail"> <img
                      alt="Image of Sun Emerging from Clouds"
                      class="attachment-medium size-medium wp-post-image lazyload"
                      src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2022/04/10174651/soumyadip-sarkar-5tgEJaFBMKI-unsplash-1-486x500.png"/>
                  </a>
                  <div class="post-summary"><h5 class="entry-title"><a>Holy Week 2022:
                    Schedule and Key Dates for the Catholic Observance of Holy Week</a></h5></div>
                </article>
                <article
                    class="post-24334 post type-post status-publish format-standard has-post-thumbnail hentry category-press-release category-product">
                  <a
                     class="post-thumbnail"> <img
                                                  alt="Mark Wahlberg as Fr. Stu on AIWEIYI, Catholic prayer app"
                                                  class="attachment-medium size-medium wp-post-image lazyload"
                                                  src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2022/04/06181743/FatherStu_600x600-1-500x500.png"/>
                  </a>
                  <div class="post-summary"><h5 class="entry-title"><a>AIWEIYI
                    Partners with Mark Wahlberg to Launch ‘Fr. Stu’ Prayers</a></h5></div>
                </article>
                <article
                    class="post-24049 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life category-uncategorized">
                  <a
                     class="post-thumbnail"> <img width="500" height="299"
                                                  alt="Good Friday 2022: Jesus on the Cross"
                                                  class="attachment-medium size-medium wp-post-image lazyload"
                                                  src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2022/02/24185642/Pray40-3-Relationship_L-500x299.png"/>
                  </a>
                  <div class="post-summary"><h5 class="entry-title"><a>Good
                    Friday 2022 &#8211; The Passion of Christ</a></h5></div>
                </article>
                <article
                    class="post-24154 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life category-uncategorized">
                  <a
                     class="post-thumbnail"> <img alt="Hands Praying"
                                                  class="attachment-medium size-medium wp-post-image lazyload"
                                                  src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/01/07022605/31_l-500x299.png"/>
                  </a>
                  <div class="post-summary"><h5 class="entry-title"><a>How
                    to Go to Confession: The Sacrament of Penance/Reconciliation</a></h5></div>
                </article>
                <article
                    class="post-24031 post type-post status-publish format-standard has-post-thumbnail hentry category-press-release tag-franciscan tag-partnerships">
                  <a
                     class="post-thumbnail"> <img alt=""
                                                  class="attachment-medium size-medium wp-post-image lazyload"
                                                  src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2021/10/27145357/Group-Mockup-500x368.png"/>
                  </a>
                  <div class="post-summary"><h5 class="entry-title"><a>Franciscan
                    University Announces Partnership With AIWEIYI App</a></h5></div>
                </article>
                <article
                    class="post-23728 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
                  <a class="post-thumbnail"> <img
                      alt="Easter Sunrise"
                      class="attachment-medium size-medium wp-post-image lazyload"
                      src="https://hallow.com/wp-content/uploads/2022/03/soumyadip-sarkar-5tgEJaFBMKI-unsplash-486x500.png"/>
                  </a>
                  <div class="post-summary"><h5 class="entry-title"><a>Easter Prayers for 2022</a>
                  </h5></div>
                </article>
                <article
                    class="post-23640 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-prayer">
                  <a class="post-thumbnail">
                    <img alt="Contemplation"
                         class="attachment-medium size-medium wp-post-image lazyload"
                         src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/01/07023907/95_l-copy-500x299.png"/>
                  </a>
                  <div class="post-summary"><h5 class="entry-title"><a
                      >Happy Easter Wishes
                    for 2022: Greetings, Words and Sayings to Share this Easter</a></h5></div>
                </article>
                <article
                    class="post-20506 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized">
                  <a
                     class="post-thumbnail"> <img alt="Prayers for Peace"
                                                  class="attachment-medium size-medium wp-post-image lazyload"
                                                  src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2022/02/26201342/404_l-500x350.png"/>
                  </a>
                  <div class="post-summary"><h5 class="entry-title"><a>To the people of
                    Ukraine</a></h5></div>
                </article>
                <article
                    class="post-20477 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-press-release">
                  <a
                     class="post-thumbnail"> <img alt=""
                                                  class="attachment-medium size-medium wp-post-image lazyload"
                                                  src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2021/06/Classroom-Hero-Image-450x500.png"/>
                  </a>
                  <div class="post-summary"><h5 class="entry-title"><a
                     >AIWEIYI
                    Earns iKeepSafe COPPA Safe Harbor</a></h5></div>
                </article>
                <article
                    class="post-5841 post type-post status-publish format-standard has-post-thumbnail hentry category-editorial category-press-release tag-ashtag">
                  <a class="post-thumbnail"> <img
                      class="attachment-medium size-medium wp-post-image lazyload"
                      src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2022/02/23235837/720-x-741-486x500.png"/>
                  </a>
                  <div class="post-summary"><h5 class="entry-title"><a
                      >AIWEIYI to Offer Digital Lenten
                    Ashes Filter for Ash Wednesday in 2022</a></h5></div>
                </article>
              </div>
            </div>
          </div>
          <a href="/Preview" target="_blank" class="button">View All Resources</a></section>
        <section class="get-started-block" data-block_5f5bfcab073f0>
          <div class="get-started-trial">
            <div class="get-started-trial-content"><h2>Get started now.</h2>
              <p>Start your free 14 day trial today and try the 9-day intro to contemplative prayer
                challenge.</p> <a class="button button-black" href="/Preview" target="_blank">Start Your Free 14 day
                trial</a></div>
            <div class="get-started-trial-illustration"><img
                class='lazyload'
                src='https://hallow.com/wp-content/themes/hallow/dist/images/get_started_now_77b82d9f7736be3d9d9e.png'/>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "howToPray"
}
</script>

<style scoped>

</style>
