<template>
  <header class="banner">
    <div class="container"><a class="brand" href="/">AIWEIYI</a>
      <button class="nav-toggle" id="nav-toggle"></button>
      <nav class="nav-primary" id="navigation" style="margin-right: 90px;margin-left: 0;">
        <div class="menu-primary-container">
          <ul id="menu-primary" class="nav">
            <li id="menu-item-3774"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3774"><a
                href="/howToPray">How to Pray</a></li>
            <li id="menu-item-305"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-305"><a
                href="/features">The App</a></li>
            <li id="menu-item-3812"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3812"><a
                href="/communities">Parishes &#038; Schools</a></li>
            <li id="menu-item-3773"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3773"><a
                href="/share">Share</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
name: "header.vue"
}
</script>

<style scoped>

</style>
