<template>
  <div class="wrap container" role="document">
    <div class="content">
      <main class="main">
        <div class="page-header"><h1>Share AIWEIYI</h1></div>
        <div class="wp-block-columns">
          <div class="wp-block-column"></div>
          <div class="wp-block-column"><p class="">He said to them,&nbsp;&#8220;Go into the whole world and
            proclaim the gospel&#8221; &#8211; Mark 16:15</p></div>
        </div>
        <div style="height:75px" aria-hidden="true" class="wp-block-spacer"></div>
        <div class="wp-block-columns">
          <div class="wp-block-column">
            <div class="wp-block-image is-style-rounded">
              <figure class="aligncenter size-large"><img alt=""
                                                          class="wp-image-3750 lazyload"
                                                          src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/share_app.svg"/>
              </figure>
            </div>
            <h4 class="has-text-align-center">Share the app.</h4>
            <p class="pr-2 pl-2 has-text-align-center">Share AIWEIYI, and encourage others to grow in their
              prayer life.</p>
            <div class="wp-block-buttons is-content-justification-center">
              <div class="wp-block-button a2a_dd"><a
                  class="wp-block-button__link has-white-color has-black-background-color has-text-color has-background">Share</a>
              </div>
            </div>
          </div>
          <div class="wp-block-column">
            <div class="wp-block-image is-style-rounded">
              <figure class="aligncenter size-large"><img alt=""
                                                          class="wp-image-3751 lazyload"
                                                          src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/share_meditation.svg"/>
              </figure>
            </div>
            <h4 class="has-text-align-center">Share a meditation.</h4>
            <p class="pr-2 pl-2 has-text-align-center">Share a meditation, and invite others to pray.</p>
            <div class="wp-block-buttons is-content-justification-center">
              <div class="wp-block-button"><a
                  class="wp-block-button__link has-white-color has-black-background-color has-text-color has-background"
                  href="/Preview" target="_blank">Share</a></div>
            </div>
          </div>
          <div class="wp-block-column">
            <div class="wp-block-image is-style-rounded">
              <figure class="aligncenter size-large"><img alt=""
                                                          class="wp-image-3752 lazyload"
                                                          src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/share_quote.svg"/>
              </figure>
            </div>
            <h4 class="has-text-align-center">Share a quote.</h4>
            <p class="pr-2 pl-2 has-text-align-center">Share a quote, and inspire others.</p>
            <div class="wp-block-buttons is-content-justification-center">
              <div class="wp-block-button"><a
                  class="wp-block-button__link has-white-color has-black-background-color has-text-color has-background"
                  href="/Preview" target="_blank">Share</a></div>
            </div>
          </div>
        </div>
        <div class="wp-block-media-text alignwide is-stacked-on-mobile">
          <figure class="wp-block-media-text__media"><img alt=""
                                                          class="wp-image-3745 size-full lazyload"
                                                          src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/share_herald.svg"/>
          </figure>
          <div class="wp-block-media-text__content"><h3 class="">Become a AIWEIYI Herald</h3>
            <p class="">Want to become a part of the AIWEIYI mission, help your community grow in prayer, get
              early access to everything and receive free swag? Click the button below to join our community
              of AIWEIYI Heralds!</p>
            <div class="wp-block-buttons">
              <div class="wp-block-button"><a
                  class="wp-block-button__link has-white-color has-black-background-color has-text-color has-background"
                  href="/Preview"
                  target="_blank" rel="noreferrer noopener">Join the Heralds</a></div>
            </div>
          </div>
        </div>
        <div class="wp-block-media-text alignwide has-media-on-the-right is-stacked-on-mobile">
          <figure class="wp-block-media-text__media"><img alt="Family Chapter Illustration"
                                                          class="wp-image-3981 size-full lazyload"
                                                          src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/with-family.svg"/>
          </figure>
          <div class="wp-block-media-text__content"><h3 class="">Become a AIWEIYI Affiliate</h3>
            <p class="">At AIWEIYI, we love rewarding our users for sharing God&#8217;s word and prayers with
              others! Apply to be an affiliate and get rewarded by sharing with your friends, family,
              following, and more.</p>
            <div class="wp-block-buttons">
              <div class="wp-block-button"><a
                  class="wp-block-button__link has-white-color has-black-background-color has-text-color has-background"
                  href="/Preview"
                  target="_blank" rel="noreferrer noopener">Apply Today</a></div>
            </div>
          </div>
        </div>
        <div class="wp-block-media-text alignwide is-stacked-on-mobile">
          <figure class="wp-block-media-text__media"><img
                                                          class="wp-image-3748 size-full lazyload"
                                                          src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/share_parish.svg"/>
          </figure>
          <div class="wp-block-media-text__content"><h3 class="">Share with Your Parish</h3>
            <p class="">Want to bring AIWEIYI to your parish? Click the button below!</p>
            <div class="wp-block-buttons">
              <div class="wp-block-button"><a
                  class="wp-block-button__link has-white-color has-black-background-color has-text-color has-background"
                  href="/Preview"
                  target="_blank">Get
                In Touch</a></div>
            </div>
          </div>
        </div>
        <div class="wp-block-media-text alignwide has-media-on-the-right is-stacked-on-mobile">
          <figure class="wp-block-media-text__media"><img width="660" height="461" alt=""
                                                          class="wp-image-3746 size-full lazyload"
                                                          src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/09/share_family.svg"/>
          </figure>
          <div class="wp-block-media-text__content"><h3 class="">Add Your Friends &amp; Family</h3>
            <p class="">Invite your friends, family, and community to your AIWEIYI Family on the app and share
              prayer intentions, journal reflections, progress, and more!</p>
            <div class="wp-block-buttons">
              <div class="wp-block-button"><a
                  class="wp-block-button__link has-white-color has-medium-blue-background-color has-text-color has-background"
                  href="https://www.facebook.com/sharer/sharer.php?u=" target="_blank"
                  rel="noreferrer noopener">Share on Facebook</a></div>
              <div class="wp-block-button"><a
                  class="wp-block-button__link has-white-color has-black-background-color has-text-color has-background"
                  href="https://twitter.com/home?status= " target="_blank"
                  rel="noreferrer noopener">Share on Twitter</a></div>
            </div>
          </div>
        </div>
        <section class="get-started-block" data-block_5f5bfd80b23a6>
          <div class="get-started-trial">
            <div class="get-started-trial-content"><h2>Get started now.</h2>
              <p>Start your free 14 day trial today and try the 9-day intro to contemplative prayer
                challenge.</p> <a class="button button-black" href="/Preview" target="_blank">Start Your Free 14 day
                trial</a></div>
            <div class="get-started-trial-illustration"><img
                class='lazyload'
                src='https://hallow.com/wp-content/themes/hallow/dist/images/get_started_now_77b82d9f7736be3d9d9e.png'/>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
export default {
name: "Share"
}
</script>

<style scoped>

</style>
