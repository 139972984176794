<template>
  <section>
    <footer class="footer">
      <div class="container">
        <div class="footer-trailer">
          <div class="footer-copyright">© {{ yearTime }} AIWEIYI, Inc.</div>
          <div class="footer-props">
            <router-link to="/privacy-policy">Privacy Policy</router-link>
            <router-link to="/terms-of-service">Terms of Service</router-link>
            <a href="javascript:;">Copyright</a>
          </div>
        </div>
      </div>
    </footer>
    <section>
      <div id="consent-summary" class="consent-info">
        <div><h4>🍪 Cookies</h4> We use cookies to enhance your experience, analyze traffic, and for security and
          marketing. For more info
          see our <a href="/cookie-policy">Cookie Policy</a>.
        </div>
        <div>
          <button id="manage-cookies" class="button">Manage</button>
          <button id="accept-all-cookies" class="consent-approval button" data-consent="7">Accept All</button>
        </div>
        <button class="decline" title="Decline"></button>
      </div>
      <div id="consent-details" class="consent-info">
        <div><h4>🍪 Cookies</h4> When you visit our website, we may store cookies on your browser for your security and
          to help us improve
          your experience on the website and in the app. The information does not usually identify you directly, but
          it can give you a safer and more personalized experience. Because we respect your right to privacy, you are
          able to choose not to allow some types of cookies. Blocking some types of cookies may affect your experience
          on the site. See our <a href="/cookie-policy">cookie policy</a> for more details on our cookies.
        </div>
        <form>
          <div id="functionality-approval-container" class="checkbox-container  consent-input "><input
              id="functionality-approval" name="functionality-approval" data-consent="0"
              class="custom-checkbox-input  consent-checks " type="checkbox" checked disabled/>
            <div id="functionality-approval-box" class="custom-box">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect class="indicator" x="0" y="0" rx="5" ry="5" width="100%" height="100%"/>
                <path d="M10 15l3 3l7 -7" class="checkmark" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <label for="functionality-approval">Strictly Necessary Cookies</label>
            <div class="expander">
              <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="arrow-path" d="M1 7.5l5 -5l5 5" stroke="black" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="dropdown-content">These cookies are necessary for the website to function and cannot be
              switched off in our systems. They are usually only set in response to actions made by you which
              amount to a request for services, such as setting your privacy preferences, logging in, or filling
              in forms. These also include cookies we may rely on for fraud prevention. You can set your browser
              to block or alert you about these cookies, but some parts of the site will not then work.
            </div>
          </div>
          <div id="personalization-approval-container" class="checkbox-container  consent-input "><input
              id="personalization-approval" name="personalization-approval" data-consent="1"
              class="custom-checkbox-input  consent-checks " type="checkbox" checked/>
            <div id="personalization-approval-box" class="custom-box">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect class="indicator" x="0" y="0" rx="5" ry="5" width="100%" height="100%"/>
                <path d="M10 15l3 3l7 -7" class="checkmark" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <label for="personalization-approval">Performance Cookies</label>
            <div class="expander">
              <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="arrow-path" d="M1 7.5l5 -5l5 5" stroke="black" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="dropdown-content">These cookies allow us to count visits and traffic sources so we can
              measure and improve the performance of our site. They help us to know which pages are the most and
              least popular and see how visitors move around the site. All information these cookies collect is
              aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have
              visited our site, and will not be able to monitor its performance.
            </div>
          </div>
          <div id="analytics-approval-container" class="checkbox-container  consent-input "><input
              id="analytics-approval" name="analytics-approval" data-consent="2"
              class="custom-checkbox-input  consent-checks " type="checkbox" checked/>
            <div id="analytics-approval-box" class="custom-box">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect class="indicator" x="0" y="0" rx="5" ry="5" width="100%" height="100%"/>
                <path d="M10 15l3 3l7 -7" class="checkmark" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <label for="analytics-approval">Functional Cookies</label>
            <div class="expander">
              <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="arrow-path" d="M1 7.5l5 -5l5 5" stroke="black" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="dropdown-content">These cookies enable us to remember choices you have made in the past in
              order to provide enhanced functionality and personalisation (e.g. what language you prefer). If you
              do not allow these cookies then some or all of these services may not function properly.
            </div>
          </div>
          <div id="ads-approval-container" class="checkbox-container  consent-input "><input id="ads-approval"
                                                                                             name="ads-approval"
                                                                                             data-consent="4"
                                                                                             class="custom-checkbox-input  consent-checks "
                                                                                             type="checkbox" checked/>
            <div id="ads-approval-box" class="custom-box">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect class="indicator" x="0" y="0" rx="5" ry="5" width="100%" height="100%"/>
                <path d="M10 15l3 3l7 -7" class="checkmark" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <label for="ads-approval">Targeting Cookies</label>
            <div class="expander">
              <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="arrow-path" d="M1 7.5l5 -5l5 5" stroke="black" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="dropdown-content">These cookies may be set through our site by our advertising partners.
              They may be used by those companies to build a profile of your interests and show you relevant ads
              on other sites. They do not store directly personal information, but are based on uniquely
              identifying your browser and internet device. If you do not allow these cookies, you will experience
              less targeted advertising.
            </div>
          </div>
          <button id="consent-save" class="consent-approval button">Save</button>
        </form>
        <button class="decline" title="Decline"></button>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  data() {
    return {
      yearTime: new Date().getFullYear()
    };
  }
};
</script>
