import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import "@/style/bootstrap.css";
// import "@/style/style.css";
// import "@/style/core.css";
// import "@/style/home.css";
// import "@/style/footer.css";
import "@/style/header.css";
import "@/style/app.css";
import "@/style/more.css";

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
