import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PrivacyPage from "../views/PrivacyTerms/PrivacyPage.vue";
import TermsPage from "../views/PrivacyTerms/TermsPage.vue";
import howToPray from "../views/childPage/HowToPray.vue";
import Features from "../views/childPage/Features.vue";
import Communities from "../views/childPage/Communities.vue";
import Share from "../views/childPage/Share.vue";
import InnerPage from "../views/childPage/InnerPage.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/support-me",
    name: "contact",
    component: Home
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: PrivacyPage
  },
  {
    path: "/terms-of-service",
    name: "terms",
    component: TermsPage
  },
  {
    path: "/read-terms",
    name: "terms",
    component: Home
  },{
    path: "/howToPray",
    name: "howToPray",
    component: howToPray
  },{
    path: "/Features",
    name: "Features",
    component: Features
  },{
    path: "/Communities",
    name: "Communities",
    component: Communities
  },{
    path: "/Share",
    name: "Share",
    component: Share
  },{
    path: "/Preview",
    name: "InnerPage",
    component: InnerPage
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
