<template>
  <div id="app">
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import DefaultParams from "@/utils/defalut_params.js";
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/HallowFooter";
export default {
  components: {
    Header,
    Footer
  },
  created() {
    DefaultParams.init(this.$route.query);
  }
};
</script>
<style >

