import { render, staticRenderFns } from "./Features.vue?vue&type=template&id=3be8e7ab&scoped=true&"
import script from "./Features.vue?vue&type=script&lang=js&"
export * from "./Features.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be8e7ab",
  null
  
)

export default component.exports