<template>
  <div class="wrap container" role="document">
    <div class="content">
      <main class="main">
        <section class="hero" data-interpolate-color="#F2E7F8">
          <div class="hero-content"><h1>A Catholic Prayer &#038; Meditation App</h1><h5>Pray Every Day this Easter
            with AIWEIYI, the Good Catholic App.</h5>
            <div class="download-options"><a href="/Preview" class="app-store"
                                             target="_blank"></a> <a href="/Preview" target="_blank"
                                                                     class="play-store"></a>
            </div>
            <div class="hero-device device">
              <div class="screen">
                <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_1.jpg"
                       data-play-on-visible
                       preload="metadata"
                       muted playsinline loop>
                  <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_1.mp4" type="video/mp4">
                </video>
              </div>
            </div>
          </div>
        </section>
        <section class="scroll-bounds">
          <section class="device floating-device">
            <div class="screens" id="screens">
              <div class="screen screen1" id="screen1">
                <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_1.jpg" muted playsinline>
                  <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_1.mp4" type="video/mp4">
                </video>
              </div>
              <div class="screen screen2" id="screen2">
                <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_2.jpg" muted playsinline>
                  <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_2.mp4" type="video/mp4">
                </video>
              </div>
              <div class="screen screen3" id="screen3">
                <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_3.jpg" muted playsinline>
                  <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_3.mp4" type="video/mp4">
                </video>
              </div>
              <div class="screen screen4" id="screen4">
                <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_4.jpg" muted playsinline>
                  <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_4.mp4" type="video/mp4">
                </video>
              </div>
            </div>
          </section>
          <section class="background" id="background"></section>
          <section class="home-block quotes"
                   data-interpolate-color="#F2E7F8"
                   data-video-screen="screen1">
            <div class="quote-block">
              <div class="quote-stars"></div>
              <q>I need this app. It&#039;s put quiet, prayerful time with God back in my life.</q></div>
            <div class="quote-block">
              <div class="quote-stars"></div>
              <q>I can tell already that this is going to be the most moving, impactful, life-changing Lenten
                season of my life.</q></div>
            <div class="quote-block">
              <div class="quote-stars"></div>
              <q>If anyone wants to start a prayer life, this is the way to go. I went from not praying at all
                to praying twice a day.</q></div>
            <div class="quote-block">
              <div class="quote-stars"></div>
              <q>I hate quoting an old, over-used cliche, but I&#039;ve been very lost. And I think for the
                first time in a while I may be found.</q></div>
            <div class="quote-block">
              <div class="quote-stars"></div>
              <q>AIWEIYI has changed my life and my marriage.</q></div>
            <div class="quote-block">
              <div class="quote-stars"></div>
              <q>My experience with AIWEIYI so far has revolutionized my prayer life.</q></div>
          </section>
          <section class="home-block pray"
                   data-interpolate-color="#C49EE7"
                   data-video-screen="screen2">
            <div class="block-device device">
              <div class="screen">
                <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_2.jpg"
                       data-play-on-visible
                       preload="metadata"
                       muted playsinline loop>
                  <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_2.mp4" type="video/mp4">
                </video>
              </div>
            </div>
            <div class="block-content"><h2>Pray</h2>
              <p>Pray with the reading from the daily Gospel each morning in just 5, 10, or 15 minutes</p>
            </div>
            <div class="block-image">
              <div class="lottie-wrapper">
                <div class="lottie animate"
                     data-anim-path="https://hallow.com/wp-content/themes/hallow/dist/images/animation_pray_d8a8a35ae4134537b0ca.json"></div>
              </div>
            </div>
          </section>
          <section class="home-block sleep content-on-right"
                   data-interpolate-color="#0A045A"
                   data-video-screen="screen3">
            <div class="block-device device">
              <div class="screen">
                <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_3.jpg"
                       data-play-on-visible
                       preload="metadata"
                       muted playsinline loop>
                  <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_3.mp4" type="video/mp4">
                </video>
              </div>
            </div>
            <div class="block-content"><h2> Sleep</h2>
              <p>Fall asleep with sleep Bible stories from Fr. Mike Schmitz, Jonathan Roumie, or Night Prayer
                from the Liturgy of the Hours</p></div>
            <div class="block-image">
              <div class="lottie-wrapper">
                <div class="lottie animate"
                     data-anim-path="https://hallow.com/wp-content/themes/hallow/dist/images/animation_sleep_d02ee444913546f803f2.json"></div>
              </div>
            </div>
          </section>
          <section class="home-block meditate"
                   data-interpolate-color="#52AA7E"
                   data-video-screen="screen4">
            <div class="block-device device">
              <div class="screen">
                <video poster="https://d11f3tjfa4485m.cloudfront.net/videos/video_4.jpg"
                       data-play-on-visible
                       preload="metadata"
                       muted playsinline loop>
                  <source src="https://d11f3tjfa4485m.cloudfront.net/videos/video_4.mp4" type="video/mp4">
                </video>
              </div>
            </div>
            <div class="block-content"><h2>Meditate</h2>
              <p>Meditate on scripture with the daily Rosary on your way to work, with your morning coffee, or
                as you go about your day</p></div>
            <div class="block-image">
              <div class="lottie-wrapper">
                <div class="lottie animate"
                     data-anim-path="https://hallow.com/wp-content/themes/hallow/dist/images/animation_meditate_f66a70836d07c6602fe2.json"></div>
              </div>
            </div>
          </section>
          <section class="home-block features"
                   data-interpolate-color="#ADD9F7"
                   data-marquee="true">
            <div class="screens">
              <div class="device device1"></div>
              <div class="device device2"></div>
              <div class="device device3 clear"></div>
              <div class="device device4"></div>
              <div class="device device5"></div>
            </div>
          </section>
        </section>
        <section class="outside-scroll">
          <div class="wp-block-columns">
            <div class="wp-block-column">
              <div class="wp-block-image centered is-style-default">
                <figure class="aligncenter size-large is-resized">
                  <img alt="" width="120" class="wp-image-3801 lazyload" src="@/assets/img/svg/find-peace.svg"/>
                </figure>
              </div>
              <h4 class="has-text-align-center">Find Peace.</h4>
              <p class="pr-3 pb-2 pl-3 has-text-align-center">Let God bring you His peace with the Calm
                Praylist, Daily Gospel, Rosary, &amp; Night Prayer</p></div>
            <div class="wp-block-column">
              <div class="wp-block-image centered is-style-default">
                <figure class="aligncenter size-large is-resized">
                  <img alt="" width="120" class="wp-image-3803 lazyload" src="@/assets/img/svg/pray-well.svg"/>
                </figure>
              </div>
              <h4 class="has-text-align-center">Pray Your Way.</h4>
              <p class="pr-3 pb-2 pl-3 has-text-align-center">Choose from 500+ sessions, 5-30 minute lengths,
                3 guides, and 9 background music options including Gregorian chant</p></div>
            <div class="wp-block-column">
              <div class="wp-block-image centered is-style-default">
                <figure class="aligncenter size-large is-resized">
                  <img alt="" width="120" src="@/assets/img/svg/play-more.svg"/>
                </figure>
              </div>
              <h4 class="has-text-align-center">Build a Habit.</h4>
              <p class="pr-3 pb-2 pl-3 has-text-align-center">Build a real habit of prayer every day by
                setting goals, journaling, tracking streaks, and setting reminders</p></div>
          </div>
          <div class="testimonial testimonial-">
            <div class="testimonial-image">
              <img alt="AIWEIYI App Bishop Rhoades" class="lazyload" src="@/assets/img/people.png"/>
            </div>
            <blockquote class="testimonial-content"><p>&#8220;AIWEIYI is an excellent resource for people
              searching for deeper spiritual lives, especially the younger generation of Catholics today. It
              helps make clear that a relationship with God is and can be extremely personal and can be a
              great source of peace, joy, and strength.&#8221;</p></blockquote>
          </div>
          <section class="cloud-block" data-block_5f68bf4f1ab65>
            <div class="cloud-block__inner">
              <figure><img
                  alt="The Wall Street Journal"
                  src="@/assets/img/the-wall.png"
                  class="lazyload"
              />
              </figure>
              <figure><img
                  alt="Tech Crunch Logo"
                  class="lazyload"
                  src="@/assets/img/tech-crunch.png"/>
              </figure>
              <figure><img
                  alt="Forbes Logo"
                  class="lazyload"
                  src="@/assets/img/forbes.png"/>
              </figure>
              <figure><img
                  alt=""
                  class="lazyload"
                  src="@/assets/img/fortune.png"/>
              </figure>
              <figure><img
                  alt=""
                  class="lazyload"
                  src="@/assets/img/aleteia.png"/>
              </figure>
              <figure><img
                  alt="EWTN Catholic Network"
                  class="lazyload"
                  src="@/assets/img/aleteia.png"/>
              </figure>
            </div>
          </section>
          <section class="related-posts-block"><h2>Resources</h2>
            <div class="carousel-wrapper">
              <div class="carousel" id="carousel">
                <div class="carousel-block">
                  <article
                      class="post-24469 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
                    <a class="post-thumbnail">
                      <img width="486" height="500" alt="Image of Sun Emerging from Clouds"
                           class="attachment-medium size-medium wp-post-image lazyload"
                           src="@/assets/img/soumyadip.png"/>
                    </a>
                    <div class="post-summary"><h5 class="entry-title"><a>Holy Week 2022:
                      Schedule and Key Dates for the Catholic Observance of Holy Week</a></h5></div>
                  </article>
                  <article
                      class="post-24334 post type-post status-publish format-standard has-post-thumbnail hentry category-press-release category-product">
                    <a
                        class="post-thumbnail">
                      <img
                          alt="Mark Wahlberg as Fr. Stu on AIWEIYI, Catholic prayer app"
                          class="attachment-medium size-medium wp-post-image lazyload"
                          src="@/assets/img/FatherStu.png"/>
                    </a>
                    <div class="post-summary"><h5 class="entry-title"><a
                    >AIWEIYI
                      Partners with Mark Wahlberg to Launch ‘Fr. Stu’ Prayers</a></h5></div>
                  </article>
                  <article
                      class="post-24154 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life category-uncategorized">
                    <a
                        class="post-thumbnail">
                      <img
                          class="attachment-medium size-medium wp-post-image lazyload"
                          src="https://s3.amazonaws.com/hallow.com/wp-content/uploads/2020/01/07022605/31_l-500x299.png"/>
                    </a>
                    <div class="post-summary"><h5 class="entry-title"><a
                    >How
                      to Go to Confession: The Sacrament of Penance/Reconciliation</a></h5></div>
                  </article>
                  <article
                      class="post-24031 post type-post status-publish format-standard has-post-thumbnail hentry category-press-release tag-franciscan tag-partnerships">
                    <a
                        class="post-thumbnail"> <img
                        class="attachment-medium size-medium wp-post-image lazyload"
                        src="@/assets/img/Group.png"/>
                    </a>
                    <div class="post-summary"><h5 class="entry-title"><a>Franciscan
                      University Announces Partnership With AIWEIYI App</a></h5></div>
                  </article>
                  <article
                      class="post-23728 post type-post status-publish format-standard has-post-thumbnail hentry category-prayer-life">
                    <a class="post-thumbnail">
                      <img
                          alt="Easter Sunrise"
                          class="attachment-medium size-medium wp-post-image lazyload"
                          src="@/assets/img/soumyadip.png"/>
                    </a>
                    <div class="post-summary"><h5 class="entry-title"><a>Easter Prayers for
                      2022</a></h5></div>
                  </article>
                  <article
                      class="post-23640 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-prayer">
                    <a class="post-thumbnail">
                      <img alt="Contemplation"
                           class="attachment-medium size-medium wp-post-image lazyload"
                           src="@/assets/img/95_l-copy.png"/>
                    </a>
                    <div class="post-summary"><h5 class="entry-title"><a>Happy Easter
                      Wishes for 2022: Greetings, Words and Sayings to Share this Easter</a></h5>
                    </div>
                  </article>
                  <article
                      class="post-20506 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized">
                    <a
                        class="post-thumbnail">
                      <img alt="Prayers for Peace"
                           class="attachment-medium size-medium wp-post-image lazyload"
                           src="@/assets/img/404_l.png"/>
                    </a>
                    <div class="post-summary"><h5 class="entry-title"><a>To the people
                      of Ukraine</a></h5></div>
                  </article>
                  <article
                      class="post-20477 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-press-release">
                    <a
                        class="post-thumbnail"> <img
                        class="attachment-medium size-medium wp-post-image lazyload"
                        src="@/assets/img/Classroom.png"/>
                    </a>
                    <div class="post-summary"><h5 class="entry-title"><a>AIWEIYI
                      Earns iKeepSafe COPPA Safe Harbor</a></h5></div>
                  </article>
                  <article
                      class="post-5841 post type-post status-publish format-standard has-post-thumbnail hentry category-editorial category-press-release tag-ashtag">
                    <a class="post-thumbnail"> <img
                        alt=""
                        class="attachment-medium size-medium wp-post-image lazyload"
                        src="@/assets/img/720-x.png"/>
                    </a>
                    <div class="post-summary"><h5 class="entry-title"><a>AIWEIYI to Offer Digital
                      Lenten Ashes Filter for Ash Wednesday in 2022</a></h5></div>
                  </article>
                </div>
              </div>
            </div>
          </section>
          <section class="get-started-block">
            <div class="get-started-plan"><h2>Try it for free.</h2>
              <p>Unlock 500+ prayers and meditations on finding calm, growing in humility, re-discovering the
                Saints, and falling asleep to Scripture</p>
              <div class="get-started-plan-options"><a
                  class="get-started-plan-option best-value"><h4
                  class="option-title">Annual</h4> <span
                  class="option-price">$59.99/yr (~$4.99 / month)</span> <span
                  class="option-trial">14 day trial</span>
              </a> <a class="get-started-plan-option"><h4 class="option-title">Monthly</h4>
                <span class="option-price">$8.99/mo</span> <span class="option-trial">14 day trial</span>
              </a></div>
            </div>
          </section>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
// import Footer from "@/components/Footer/Footer";

export default {
  name: "Home",
  components: {
    // Footer
  },
  data() {
    return {
      elements: [],
      screen: null,
      timer: null
    };
  },
  methods: {
    addClass() {
      if (this.animated) return;
      this.animated = "animated";
      setTimeout(() => {
        this.animated = "";
      }, 2000);
    },
    init() {
      this.elements = document.querySelectorAll("[data-interpolate-color]");
      console.log("this.elemets", this.elements);
      window.addEventListener("scroll", this.handleScroll.bind(this));
    },
    handleScroll() {
      var t = this,
          e = document.getElementById("background"),
          i = Array.from(this.elements)
              .map(function (e) {
                return t.visibility(e);
              })
              .filter(function (t) {
                return t.visible;
              });
      if (i.length <= 0 || 2 < i.length)
        return (e.style.backgroundColor = "#FFF"), void this.pauseAll();
      (i = 2 == i.length ? i[1] : i[0]),
          (e.style.backgroundColor = i.color),
          (i = i.el.dataset.videoScreen);
      this.screen != i && this.showScreen(i);
    },
    visibility: function (t, e) {
      e = e || 200;
      var i = t.getBoundingClientRect(),
          r = Math.max(document.documentElement.clientHeight, window.innerHeight),
          s = i.bottom - e < 0;
      return (
          (e = 0 <= i.top - r + e),
              {
                el: t,
                visible: !s && !e,
                color: t.dataset.interpolateColor
              }
      );
    },
    showScreen: function (t) {
      this.screen = t;
      var e,
          i,
          r = document.getElementById("screens"),
          s = document.getElementById(t);
      s &&
      (this.pauseAll(),
      (t = s.firstElementChild) && t.play(),
          (e = r.scrollLeft),
          (i = s.offsetLeft - e),
          this.animate({
            timing: function (t) {
              return t < 0.5 ? 2 * t * t : (4 - 2 * t) * t - 1;
            },
            callback: function (t) {
              r.scrollLeft = e + i * t;
            },
            duration: 350
          }));
    },
    animate: function (t) {
      var e = t.timing,
          i = t.callback,
          r = t.duration,
          s = performance.now();
      requestAnimationFrame(function t(a) {
        var n = (a - s) / r;
        (a = e((n = 1 < n ? 1 : n))), i(a), n < 1 && requestAnimationFrame(t);
      });
    },
    pauseAll: function () {
      var t = document.querySelectorAll("video:not([autoplay])");
      Array.from(t).forEach(function (t) {
        (t.currentTime = 0), t.pause();
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  }
};
</script>

<style scoped>
#home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

</style>
